<template>
  <!-- Under maintenance-->
  <div class="misc-wrapper">
    <b-link class="brand-logo">
      <MenuHeader />
    </b-link>

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-2 text-danger">
          Você foi banido por IP.
        </h2>
      </div>

      <b-card>
        <b-row class="d-flex justify-content-center">
          <b-col
            cols="12"
            class="text-center mb-1"
          >
            <h4>
              Seu IP foi banido por algum destes motivos:
            </h4>
          </b-col>

          <b-col class="mb-1">
            <ul>
              <li>
                A equipe ContasInfinity desativou sua conta devido à violação da política.
              </li>
            </ul>
          </b-col>

          <b-col cols="12">
            <p class="text-center">
              Se você acha que essa mensagem é um engano, por favor, entre em contato conosco em contato@contasinfinity.com.br
            </p>
          </b-col>
        </b-row>
      </b-card>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import {
  BLink, BFormInput, BButton, BForm, BImg, BCard, BRow, BCol
} from 'bootstrap-vue'
import MenuHeader from '@/layouts/components/MenuHeader.vue'
import store from '@/store/index'

export default {
  components: {
    BLink,
    BFormInput,
    BButton,
    BForm,
    BImg,
    MenuHeader,
    BCard,
    BRow,
    BCol
  },
  data () {
    return {
      downImg: require('@/assets/images/pages/under-maintenance.svg')
    }
  },
  computed: {
    imgUrl () {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/under-maintenance-dark.svg')
        return this.downImg
      }
      return this.downImg
    }
  },
  mounted () {
    //
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
